<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{$t('elearning_config.trainer')}} {{$t('elearning_iabm.booking_application')}}</h4>
            </template>
            <template v-slot:body>
              <searchBasic :search="search" @search-data="searchIt"/>
              <b-col lg="12" sm="12" class="text-right">
                  <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
              </b-col>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('elearning_config.trainer')}} {{$t('elearning_iabm.booking_application')}} {{$t('globalTrans.list')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <!-- <template v-slot:headerAction>
                      <b-button variant="primary btn_add_new" v-b-modal.modal-4 @click="resetId">
                        <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
                      </b-button>
                    </template> -->
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(personal_info)="data">
                                          {{$t('globalTrans.name')}} : {{ $i18n.locale === 'bn' ? data.item.name_bn : data.item.name }}
                                          <br/>
                                          {{$t('globalTrans.email')}} : {{ data.item.email }}
                                          <br/>
                                          {{$t('globalTrans.mobile')}} : {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(data.item.mobile, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(type_name)="data">
                                        <span class="capitalize">{{ data.item.type_name }}</span>
                                        </template>
                                        <template v-slot:cell(training_start_date)="data">
                                          <span class="capitalize">{{ data.item.training_start_date | dateFormat }}</span>
                                        </template>
                                        <template v-slot:cell(training_end_date)="data">
                                          <span class="capitalize">{{ data.item.training_end_date | dateFormat }}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <b-button v-if="data.item.accommodation_status == 1" v-b-modal.modal-4 variant="btn btn-success mr-1 mb-1 btn-sm" size="sm" @click="edit(data.item)">
                                              {{$t('elearning_iabm.assign')}}
                                            </b-button>
                                            <slot v-else>
                                              <b-button v-b-modal.modal-4 variant="btn btn-success mr-1 mb-1 btn-sm" size="sm" @click="edit(data.item)">
                                                {{$t('elearning_iabm.assigned')}}
                                              </b-button>
                                            </slot>
                                            <b-button v-if="data.item.accommodation_status == 2" v-b-modal.modal-form-cancel-details variant="btn btn-success mr-1 mb-1 btn-sm" size="sm" @click="cancel(data.item)">
                                              {{$t('globalTrans.cancelled')}}
                                            </b-button>
                                            <b-button v-if="data.item.booking && data.item.booking.status == 2" v-b-modal.modal-cancel-details variant="btn btn-success mr-1 mb-1 btn-sm" @click="cancelDetails(data.item)" size="sm">
                                              <i class="ri-message-2-line"></i>
                                            </b-button>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-form-cancel-details" size="md" :title="$t('globalTrans.cancelled')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <CancelComments :item="itemData"/>
        </b-modal>
        <b-modal id="modal-cancel-details" size="md" :title="$t('globalTrans.cancelled')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <CancelDetails :item="itemData"/>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { bookingApplication, circularPublicationToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import ExportPdf from './export_pdf_details'
import CancelComments from './CancelComments.vue'
import CancelDetails from './CancelDetails.vue'
import Store from '@/store'
import searchBasic from './../../../component/searchBasic'

export default {
    mixins: [ModalBaseMasterList],
    components: {
      Form,
      searchBasic,
      CancelComments,
      CancelDetails
    },
    data () {
        return {
          circularLoading: false,
          search: {
            coordinator_id: 0,
            fiscal_year_id: 0,
            circular_memo_no: 0,
            training_type_id: 0,
            training_category_id: 0,
            training_title_id: 0,
            batch_no: 0,
            course_module_id: 0,
            registration_for: 1
          },
          trainingCategoryList: [],
          trainingTitleList: [],
          trainerNameList: [],
          trainerList: [],
          circularList: [],
          allBatchListData: [],
          trainingTypeList: [],
          batchList: [],
          itemData: [],
          courseModuleList: []
        }
    },
    watch: {
      'search.training_type_id': function (newValue) {
        this.trainingCategoryList = this.getCategoryList(newValue)
      },
      'search.training_category_id': function (newValue) {
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      }
    },
    computed: {
      allBatchList () {
        const batchList = [
          { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
          { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
          { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
          { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
          { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
          { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
          { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
          { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
          { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
          { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
        ]
        return batchList
      },
      userTypeList () {
        const userList = [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Trainer' : 'প্রশিক্ষক', text_en: 'Trainer', text_bn: 'প্রশিক্ষক' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Trainee' : 'প্রশিক্ষণার্থী', text_en: 'Trainee', text_bn: 'প্রশিক্ষণার্থী' }
        ]
        return userList
      },
      fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
        formTitle () {
            return (this.editItemId === 0) ? this.$t('elearning_iabm.booking') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_iabm.booking') + ' ' + this.$t('globalTrans.entry')
        },
        columns () {
            const labels = [
              { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
              { label: this.$t('elearning_iabm.registration_for'), class: 'text-center' },
              { label: this.$t('elearning_config.organization'), class: 'text-center' },
              { label: this.$t('elearning_config.training_title'), class: 'text-center' },
              { label: this.$t('elearning_iabm.batch_no'), class: 'text-center' },
              { label: this.$t('elearning_iabm.circular_memo_no'), class: 'text-center' },
              { label: this.$t('globalTrans.personal_information'), class: 'text-center' },
              { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            let keys = []

            if (this.$i18n.locale === 'bn') {
              keys = [
                { key: 'index' },
                { key: 'user_type_name_bn' },
                { key: 'org_bn' },
                { key: 'training_title_bn' },
                { key: 'batch_name_bn' },
                { key: 'circular_memo_no' },
                { key: 'personal_info' },
                { key: 'action' }
              ]
            } else {
              keys = [
                { key: 'index' },
                { key: 'user_type_name' },
                { key: 'org' },
                { key: 'training_title' },
                { key: 'batch_name' },
                { key: 'circular_memo_no' },
                { key: 'personal_info' },
                { key: 'action' }
              ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    async created () {
      this.search = Object.assign({}, this.search, {
        org_id: this.$store.state.dataFilters.orgId,
        fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
      })
      if (this.$store.state.Auth.activeRoleId !== 1) {
        if (this.$store.state.Auth.authUser.is_org_admin !== 1 && parseInt(this.$store.state.Auth.authUser.org_id) === 12) {
          this.search.coordinator_id = this.$store.state.Auth.authUser.user_id
        }
      }
      this.loadData()
    },
    mounted () {
        core.index()
    },
    methods: {
      searchIt (search) {
        this.search = search
      },
      getCategoryList (typeId) {
        const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      },
      getTrainingTitleList (categoryId) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      },
      dataChange () {
          this.loadData()
      },
      async searchData () {
          this.loadData()
      },
      remove (item) {
          this.changeStatus(trainingElearningServiceBaseUrl, circularPublicationToggleStatus, item, 'training_e_learning', 'trainerEvaluationList')
      },
      cancelBooking (baseUrl, uri, item, destination = null, dropdownName = null) {
        Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.deleteData(baseUrl, `${uri}/${item.id}`).then(response => {
          if (response.success) {
            /** The following line commented as list is reloaded */
            // Store.dispatch('toggleStatus', item)
            if (destination !== null && dropdownName !== null) {
              Store.dispatch('toggleDropdownItemStatus', { itemId: item.id, destination: destination, dropdownName: dropdownName })
            }
            window.vm.$toast.success({
              title: 'Success',
              message: 'Data Updated Successfully',
              color: '#D6E09B'
            })
          } else {
            window.vm.$toast.error({
              title: 'Error',
              message: 'Operation failed! Please, try again.'
            })
          }
          Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
        })
      },
      // cancel (item) {
      //   window.vm.$swal({
      //     title: window.vm.$t('globalTrans.bookingCancelMsg'),
      //     showCancelButton: true,
      //     confirmButtonText: window.vm.$t('globalTrans.yes'),
      //     cancelButtonText: window.vm.$t('globalTrans.no'),
      //     focusConfirm: false
      //   }).then((result) => {
      //     if (result.isConfirmed) {
      //       this.cancelBooking(trainingElearningServiceBaseUrl, bookingCancel, item, 'training_e_learning', 'trainerEvaluationList')
      //     }
      //   })
      // },
      cancel (item) {
        this.itemData = item
      },
      cancelDetails (item) {
        this.itemData = item
      },
      loadData () {
          const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.getData(trainingElearningServiceBaseUrl, bookingApplication, params).then(response => {
              if (response.success) {
                  this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                  this.paginationData(response.data)
              }
              this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
          })
      },
      getCustomDataList (data) {
        const listData = data.map(item => {
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }

          const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            officeData.office = officeObj.text_en
            officeData.office_bn = officeObj.text_bn
          } else {
            officeData.office = ''
            officeData.office_bn = ''
          }

          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          const trainingTitleData = {}
          if (typeof trainingTitleObj !== 'undefined') {
            trainingTitleData.training_title = trainingTitleObj.text_en
            trainingTitleData.training_title_bn = trainingTitleObj.text_bn
          } else {
            trainingTitleData.training_title = ''
            trainingTitleData.training_title_bn = ''
          }
          const customItem = {}
          const userType = this.userTypeList.find(userTypeObj => userTypeObj.value === item.registration_for)
          customItem.user_type_name = userType?.text_en
          customItem.user_type_name_bn = userType?.text_bn

          const batchObj = this.allBatchList.find(doc => doc.value === parseInt(item.batch_no))
          const batchData = {}
          if (typeof batchObj !== 'undefined') {
            batchData.batch_name = batchObj.text_en
            batchData.batch_name_bn = batchObj.text_bn
          } else {
            batchData.batch_name = ''
            batchData.batch_name_bn = ''
          }
          return Object.assign({}, item, orgData, officeData, fiscalYearData, trainingTitleData, customItem, batchData)
        })
        return listData
      },
      pdfExport () {
        const reportTitle = this.$i18n.locale === 'en' ? 'Circular Publication' : 'বিজ্ঞপ্তি প্রকাশ'
        ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/fertilizer/config/report-heading/detail', 12, reportTitle, this)
      }
    }
}
</script>
