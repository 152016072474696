<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12">
                  <div class="table-wrapper table-responsive">
                    <table class="table table-striped table-hover table-bordered">
                      <thead>
                          <tr>
                            <td style="width: 15%;"> <b>{{ $t('globalTrans.comments') }}</b> </td>
                            <td style="width: 85%;">
                              {{ item.booking.cancel_reason }}
                            </td>
                          </tr>
                      </thead>
                  </table>
                  </div>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { cancelComments } from '../../api/routes'
export default {
  name: 'Details',
  props: ['item'],
  data () {
    return {
      valid: null,
      saveBtnName: this.$t('globalTrans.save'),
      errors: [],
      loading: false,
      form: {
        comment: ''
      }
    }
  },
  created () {
    if (this.item) {
      this.form.id = this.item.booking.id
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    async saveUpdate () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      result = await RestApi.postData(trainingElearningServiceBaseUrl, cancelComments, this.form)

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('CommonService/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: result.message,
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-form-cancel-details')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    EngBangNum (n) {
      if (this.$i18n.locale === 'bn') {
        return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
      } else {
        return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
      }
    }
  }
}
</script>
